import React from "react";

import TeamMembers from "../../components/TeamMembers/teamMembers";
const HowWeWork = () => {
  return (
    <div>
      <h1>HOW WE WORK PAGE </h1>
    </div>
  );
};

export default HowWeWork;
